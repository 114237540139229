<template>
  <el-card class="main" style="margin-top: 10px;">
    <div class="itemPut">
      <div style="margin: 20px"></div>
      <el-form :model="ruleForm" label-width="150px" :label-position="labelPosition" :rules="rules" ref="inserform">
        <el-form-item label="角色名称：" prop="name">
          <el-input v-model.trim="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色code：" prop="code">
          <el-input v-model.trim="ruleForm.code"></el-input>
        </el-form-item>
        <el-form-item label.trim="角色描述：" prop="remark">
          <el-input type="textarea" v-model="ruleForm.remark"></el-input>
        </el-form-item>
        <div style="margin-top: 40px; margin-left: 50px; font-weight: 700">权限设置</div>
        <div class="across"></div>
        <el-form-item label="角色管理授权菜单：" style="margin-top: 20px" prop="permsIds">
          <el-tree :data="data" show-checkbox node-key="id" ref="tree"
            :default-expanded-keys="[1, 2, 3]" :default-checked-keys="[0]" :props="defaultProps" @check="currentChecked">
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <router-link to="/role/list"><el-button>取 消</el-button></router-link>
        <el-button type="primary" style="margin-left: 50px" @click="roleAdd('inserform')">提 交</el-button>
      </div>
    </div>
  </el-card>
</template>
<script>
import { permissions } from "@/api/permission";
import { roleAdd } from "@/api/role";
export default {
  data() {
    return {
      labelPosition: "right",
      ruleForm: {
        name: "",
        remark: "",
        code: "",
        permsIds: "",
      },
      data: [], //树形菜单
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules: {
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {min: 2, max: 10, message: "长度在 2 到 10 个字符", trigger: "blur",},
        ],
        permsIds: [{ required: true, message: "请选择菜单权限", trigger: "blur" },],
        code: [{ required: true, message: "不能为空", trigger: "blur" },],
      },
    };
  },
  created() {
    this.list();
  },
  methods: {
    // 获取菜单权限
    list() {
      permissions().then((res) => {
        this.data = res.data;
      });
    },
    // 选中的节点
    currentChecked(nodeObj, SelectedObj) {
      let self = this;
      self.ruleForm.permsIds = SelectedObj.checkedKeys;
    },
    //新建角色
    roleAdd(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          roleAdd(this.ruleForm).then(() => {
            this.$router.push("/role/list");
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/overall.less";
/deep/ .main{
  height: 100%;
}
</style>
<style lang="less">

.el-textarea {
  height: 200px;
  .el-textarea__inner {
    height: 200px;
  }
}
</style>
